<template>
  <b-sidebar
    id="add-service-sidebar"
    :visible="isAddServiceSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-service-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1 bg-primary text-white">
        <h5 class="mb-0 text-white" v-if="!id">
          Nouveau service
        </h5>
        <h5 class="mb-0 text-white" v-else>
          Modifier service
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer ref="simpleRules">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent=""
          @reset.prevent=""
        >

            <b-form-group
              label="Name"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="First Name"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="name"
                  autofocus
                  trim
                  :state="errors.length > 0 ? false:null"
                  placeholder="nom de service"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Status"
              label-for="status"
            >
              <b-form-checkbox
                :checked="status"
                v-model="status"
                class="custom-control-success"
                name="status"
                switch
              />
            </b-form-group>



          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click.prevent="add"
            >
              <span v-if="!id">Ajouter</span> 
              <span v-else>Modifier</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Annuler
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BFormCheckbox 
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapActions } from "vuex"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    ToastificationContent,

    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddServiceSidebarActive',
    event: 'update:is-add-service-sidebar-active',
  },
  props: {
    isAddServiceSidebarActive: {
      type: Boolean,
      required: true,
    },
    serviceData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      id: null,
      name: null,
      status: true,
      required
    }
  },
  methods: {
    ...mapActions('services', ['addItem','updateItem']),

    notification(variant, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: variant == 'danger' ? 'XCircleIcon' : 'CheckCircleIcon',
          text: text,
          variant
        },
      })
    },

    init(close = false) {
      if(!close && this.serviceData.id) {
        this.id = this.serviceData.id
        this.name = this.serviceData.name
        this.status = this.serviceData.status ? true : false

        return
      }

      this.id = null
      this.name = null
      this.status = true
      this.$refs.simpleRules.reset()
     
    },

    add() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if(!this.id) {
            this.addItem({name: this.name, status: this.status}).then(() => {
              this.$emit('update:is-add-service-sidebar-active', false)
            })
          }else{
            this.updateItem({id: this.id, name: this.name, status: this.status}).then(() => {
              this.$emit('update:is-add-service-sidebar-active', false)
              this.$emit('refreshData')
            })
          }
        }
      })
    },

    
  },

  created()  {
    
  },

  watch: {
    isAddServiceSidebarActive(val) {
      if(!val) {
        this.init(true)
        return
      }

      this.init()
    }
  }
 
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-service-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
