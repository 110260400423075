<template>
  <b-sidebar
    id="add-sub-service-sidebar"
    :visible="isAddSubServiceSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-sub-service-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1 bg-primary text-white">
        <h5 class="mb-0 text-white" v-if="!id">
          Nouveau sous-service
        </h5>
        <h5 class="mb-0 text-white" v-else>
          Modifier sous-service
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer ref="simpleRules">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent=""
          @reset.prevent=""
        >

            <b-form-group
                label="Titre"
                label-for="titre"
            >
                <validation-provider
                    #default="{ errors }"
                    name="Titre"
                    rules="required"
                >
                    <b-form-input
                        id="titre"
                        v-model="titre"
                        autofocus
                        trim
                        :state="errors.length > 0 ? false:null"
                        placeholder="nom de service"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>

            
                
            <b-form-group
                label="Service"
                label-for="service"
            >
                <validation-provider
                    #default="{ errors }"
                    name="Service"
                    rules="required"
                >
                    <v-select
                        v-model="service_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="serviceOptions"
                        label="name"
                        :reduce="val => val.id"
                        :clearable="false"
                        input-id="service"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click.prevent="add"
            >
              <span v-if="!id">Ajouter</span> 
              <span v-else>Modifier</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Annuler
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapActions } from "vuex"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    ToastificationContent,

    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddSubServiceSidebarActive',
    event: 'update:is-add-sub-service-sidebar-active',
  },
  props: {
    isAddSubServiceSidebarActive: {
      type: Boolean,
      required: true,
    },
    serviceOptions: {
      type: Array,
      required: true,
    },
    subServiceData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      id: null,
      titre: null,
      service_id: null,
      required
    }
  },
  methods: {
    ...mapActions('subServices', ['addItem','updateItem']),

    notification(variant, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: variant == 'danger' ? 'XCircleIcon' : 'CheckCircleIcon',
          text: text,
          variant
        },
      })
    },

    init(close = false) {
      if(!close && this.subServiceData.id) {
        this.id = this.subServiceData.id
        this.titre = this.subServiceData.titre
        this.service_id = this.subServiceData.service.id

        return
      }

      this.id = null
      this.titre = null
      this.service_id = this.serviceOptions.length ? this.serviceOptions[0].id : null
      this.$refs.simpleRules.reset()
    },

    add() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if(!this.id) {
            this.addItem({titre: this.titre, service_id: this.service_id}).then((result) => {
              if(result.data.success) {
                this.notification('success',  'Insertion', 'Le sous service est bien ajouté')
                this.$emit('update:is-add-sub-service-sidebar-active', false)
              }else{
                this.notification('danger',  'Insertion', 'Le sous service n\'est pas ajouté')
              }
            })
            .catch(() => {
              this.notification('danger',  'Insertion', 'Le sous service n\'est pas ajouté')
            })
          }else{
            this.updateItem({id: this.id, titre: this.titre, service_id: this.service_id}).then((result) => {
              if(result.data.success) {
                this.notification('success',  'Modification', 'Le sous service est bien modifié')
                this.$emit('update:is-add-sub-service-sidebar-active', false)
              }else{
                this.notification('danger',  'Modification', 'Le sous service n\'est pas modifié')
              }
            })
            .catch(() => {
              this.notification('danger',  'Modification', 'Le sous service n\'est pas modifié')
            })
          }
        }
      })
    },
  },


  created()  {
    
  },

  watch: {
    isAddSubServiceSidebarActive(val) {
      if(!val) {
        this.init(true)
        return
      }

      this.init()
    }
  }
 
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';

    #add-sub-service-sidebar {
    .vs__dropdown-menu {
        max-height: 200px !important;
    }
    }
</style>
