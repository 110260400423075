<template>
  <div>
    <service-add-update
      :is-add-service-sidebar-active.sync="isAddServiceSidebarActive"
      :service-data="serviceSidebarData"
      @refreshData="refreshData"
    />

    <sub-service-add-update
      :is-add-sub-service-sidebar-active.sync="isAddSubServiceSidebarActive"
      :service-options="services"
      :sub-service-data="subServiceSidebarData"
    />

    <div class="row">
      <div class="col-md-6 mt-1">
        <b-card
          no-body
          class="mb-0"
        >

        <b-card-body class="pb-0">
          <b-card-title class="mb-0">Services</b-card-title>
        </b-card-body>

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="servicePerPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="serviceSearchQuery"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <b-button
                    variant="primary"
                    @click="addService"
                  >
                    <span class="text-nowrap">Ajouter nouveau</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>

        </div>

          <b-table
            class="position-relative"
            responsive
            :fields="serviceColumns"
            :items="filtredServices"
            :current-page="serviceCurrentPage"
            :per-page="servicePerPage"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
          >

            <template #cell(status)="data">
              <b-badge pill variant="success" v-if="data.item.status">Activé</b-badge>
              <b-badge pill variant="danger" v-else>Désactivé</b-badge>
            </template>

            <template #cell(actions)="data">
              <b-button variant="flat-info" class="btn-icon" v-ripple.400="'rgba(186, 191, 199, 0.15)'" v-b-tooltip.hover.v-info title="Modifier"  @click="editService(data.item)">
                <feather-icon icon="Edit2Icon" />
              </b-button>
              <b-button variant="flat-danger" class="btn-icon" v-ripple.400="'rgba(186, 191, 199, 0.15)'" v-b-tooltip.hover.v-danger title="Supprimer" @click="removeService(data.item.id)">
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
    
              <!-- Pagination -->
              <b-col
                cols="12"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
    
                <b-pagination
                  v-model="serviceCurrentPage"
                  :total-rows="totalServices"
                  :per-page="servicePerPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
    
              </b-col>
    
            </b-row>
          </div>

        </b-card>
      </div>

      <div class="col-md-6 mt-1">
        <b-card
          no-body
          class="mb-0"
        >

        <b-card-body class="pb-0">
          <b-card-title class="mb-0">Sous-services</b-card-title>
        </b-card-body>

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="subServicePerPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="subServiceSearchQuery"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <b-button
                    variant="primary"
                    @click="addSubService"
                  >
                    <span class="text-nowrap">Ajouter nouveau</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>

        </div>

          <b-table
            class="position-relative"
            responsive
            :fields="subServiceColumns"
            :items="filtredSubServices"
            :current-page="subServiceCurrentPage"
            :per-page="subServicePerPage"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
          >
            <template #cell(actions)="data">
              <b-button variant="flat-info" class="btn-icon" v-ripple.400="'rgba(186, 191, 199, 0.15)'" v-b-tooltip.hover.v-info title="Modifier" @click="editSubService(data.item)">
                <feather-icon icon="Edit2Icon" />
              </b-button>
              <b-button variant="flat-danger" class="btn-icon" v-ripple.400="'rgba(186, 191, 199, 0.15)'" v-b-tooltip.hover.v-danger title="Supprimer" @click="removeSubService(data.item.id)">
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </template>
          </b-table>

          <div class="mx-2 mb-2">
            <b-row>
              <!-- Pagination -->
              <b-col
                cols="12"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
    
                <b-pagination
                  v-model="subServiceCurrentPage"
                  :total-rows="totalSubServices"
                  :per-page="subServicePerPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
    
              </b-col>
    
            </b-row>
          </div>

        </b-card>
      </div>
    </div>

  </div>
</template>

<script>
  import ServiceAddUpdate from './ServiceAddUpdate.vue'
  import SubServiceAddUpdate from './SubServiceAddUpdate.vue'
  import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BCardTitle, BCardBody
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import { mapGetters, mapActions } from "vuex"
  import Ripple from 'vue-ripple-directive'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


  export default {
    components: {
      ToastificationContent,

      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      BCardBody,
      BCardTitle,

      vSelect,

      ServiceAddUpdate,
      SubServiceAddUpdate
    },

    data() {
      return {
        serviceColumns : [
          { label: 'Titre', key: 'name', sortable: true },
          { label: 'Status', key: 'status', sortable: true },
          { label: 'Actions', key: 'actions', thClass: 'w-25 text-center', tdClass: 'text-center' },
        ],
        subServiceColumns : [
          { label: 'Titre', key: 'titre', sortable: true },
          { label: 'Service', key: 'service.name', sortable: true },
          { label: 'Actions', key: 'actions', thClass: 'w-25 text-center', tdClass: 'text-center' },
        ],
        isSortDirDesc : true,
        subServicePerPage : 10,
        servicePerPage : 10,
        subServiceCurrentPage : 1,
        serviceCurrentPage : 1,
        perPageOptions : [10, 25, 50, 100],
        totalServices : 0,
        totalSubServices : 0,
        isAddServiceSidebarActive: false,
        isAddSubServiceSidebarActive: false,
        serviceSearchQuery: '',
        subServiceSearchQuery: '',
        serviceSidebarData: {id: null, name: null, status: 1},
        subServiceSidebarData: {id: null, service: null, titre: null}
      }
    },

    computed: {
      ...mapGetters('services', {'services' : 'GET_ITEMS'}),
      ...mapGetters('subServices', {'sub_services' : 'GET_ITEMS'}),

      filtredServices() {
        let services = this.services
        if(this.serviceSearchQuery.length) {
          services = services.filter(item => {
            return item.name.toLowerCase().includes(this.serviceSearchQuery.toLowerCase())
          })
        }

        this.totalServices = services.length

        return services
      },

      filtredSubServices() {
        let sub_services = this.sub_services

        if(this.subServiceSearchQuery.length) {
          sub_services = sub_services.filter(item => {
            return item.titre.toLowerCase().includes(this.subServiceSearchQuery.toLowerCase())
          })
        }
  
        this.totalSubServices = sub_services.length

        return sub_services
      },
    },

    methods: {
      ...mapActions('services', {'fetchServices' : 'fetchItems', 'removeServiceItem' : 'removeItem'}),
      ...mapActions('subServices', {'fetchSubServices' : 'fetchItems', 'removeSubServiceItem' : 'removeItem'}),

      notification(variant, title, text) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: variant == 'danger' ? 'XCircleIcon' : 'CheckCircleIcon',
            text: text,
            variant
          },
        })
      },

      refreshData() {
        this.fetchSubServices()
      },
      
      addService() {
        this.serviceSidebarData = {id: null, name: null, status: 1}
        this.isAddServiceSidebarActive = true
      },

      editService(data) {
        this.serviceSidebarData = data
        this.isAddServiceSidebarActive = true
      },

      removeService(id) {
        this.$swal({
          title: 'Êtes-vous sûr?',
          text: "Vous ne pourrez pas revenir en arrière !",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui, supprimez-le !',
          cancelButtonText: 'Annuler',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.removeServiceItem(id)
            .then(() => {
              this.notification('success',  'Supperission', 'Le service est bien supprimé')
              this.refreshData()
            })
            .catch(() => {
              this.notification('danger',  'Supperission', 'Le service n\'est pas supprimé')
            })
          }
        })
      },

      // Sub services functions

      addSubService() {
        this.subServiceSidebarData = {id: null, service: null, titre: null}
        this.isAddSubServiceSidebarActive = true
      },

      editSubService(data) {
        this.subServiceSidebarData = data
        this.isAddSubServiceSidebarActive = true
      },

      removeSubService(id) {
        this.$swal({
          title: 'Êtes-vous sûr?',
          text: "Vous ne pourrez pas revenir en arrière !",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui, supprimez-le !',
          cancelButtonText: 'Annuler',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.removeSubServiceItem(id)
            .then(() => {
              this.notification('success',  'Supperission', 'Le sous service est bien supprimé')
              this.fetchSubServices()
            })
            .catch(() => {
              this.notification('danger',  'Supperission', 'Le sous service n\'est supprimé')
            })
          }
        })
      }
    },

    created() {
      this.fetchServices()
      this.fetchSubServices()
    },

    watch: {

    },

    directives: {
      'b-tooltip': VBTooltip,
      Ripple
    },
  }
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>

